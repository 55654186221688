<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Service Forms
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            v-if="canViewThisAction('create', 'ServiceForm')"
            variant="success"
            :to="{ name: 'operation-service-forms-create' }"
            class="mobile_create_button"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Service Form</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            :active="$route.params.type == 'pending'"
            lazy
            @click="changeRoute('pending')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Pending <span class="rounded-number">{{ pendingCount }}</span></span>
            </template>
            <pending-service-form
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              @emitCountServiceForm="countServiceForms"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'pending-approval'"
            lazy
            @click="changeRoute('pending-approval')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Awaiting Approval <span class="rounded-number">{{ pendingApprovalCount }}</span></span>
            </template>
            <pending-approval-service-form
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              @emitCountServiceForm="countServiceForms"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab
            :active="$route.params.type == 'completed'"
            lazy
            @click="changeRoute('completed')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Completed <span class="rounded-number">{{ completedCount }}</span></span>
            </template>
            <confirmed-service-form
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              @emitCountServiceForm="countServiceForms"
            />
          </b-tab>

          <!-- <b-tab
            :active="$route.params.type == 'draft'"
            lazy
            @click="changeRoute('draft')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Draft <span class="rounded-number">{{ draftCount }}</span></span>
            </template>
            <draft-service-form
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              @emitCountServiceForm="countServiceForms"
            />
          </b-tab> -->

          <b-tab
            :active="$route.params.type == 'all-service-forms'"
            lazy
            @click="changeRoute('all-service-forms')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Service Forms <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-service-form
              class="mt-2 pt-75"
              :duty-officer-options="dutyOfficerOptions"
              @emitCountServiceForm="countServiceForms"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllServiceForm from './all-service-form/AllServiceForm.vue'
import PendingServiceForm from './pending-service-form/PendingServiceForm.vue'
import PendingApprovalServiceForm from './pending-approval-service-form/PendingApprovalServiceForm.vue'
import ConfirmedServiceForm from './confirmed-service-form/ConfirmedServiceForm.vue'
// import DraftServiceForm from './draft-service-form/DraftServiceForm.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllServiceForm,
    PendingServiceForm,
    ConfirmedServiceForm,
    PendingApprovalServiceForm,
    // DraftServiceForm,
  },
  data() {
    return {
      canViewThisAction,
      currentTabTitle: '',
      allCount: 0,
      pendingCount: 0,
      pendingApprovalCount: 0,
      completedCount: 0,
      draftCount: 0,
      dutyOfficerOptions: [],
      pageTabs: [
        {
          title: 'Pending',
          param: 'pending',
          count: 0,
        },
        {
          title: 'Awaiting Approval',
          param: 'pending-approval',
          count: 0,
        },
        {
          title: 'Completed',
          param: 'completed',
          count: 0,
        },
        // {
        //   title: 'Draft',
        //   param: 'draft',
        //   count: 0,
        // },
        {
          title: 'All Service Forms',
          param: 'all-service-forms',
          count: 0,
        },
      ],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countServiceForms()
    this.$http.get('operation/service-forms/respond-with/duty-officers-workflow', { params: { department: true } })
      .then(response => {
        this.dutyOfficerOptions = response.data.dutyOfficers || []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countServiceForms() {
      this.$http.get('operation/service-forms/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.pendingCount = response.data.pendingCount
          this.pendingApprovalCount = response.data.pendingApprovalCount
          this.completedCount = response.data.completedCount
          this.draftCount = response.data.draftCount

          this.pageTabs.forEach(element => {
            if (element.title === 'All Service Forms') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Pending') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.pendingCount
            } else if (element.title === 'Awaiting Approval') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.pendingApprovalCount
            } else if (element.title === 'Completed') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.completedCount
            } else if (element.title === 'Draft') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.draftCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
